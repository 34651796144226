import React from 'react'
import logo from '../../static/img/logo-b.png'
import {Link} from "@reach/router"
import SelectLanguage from "./SelectLanguage";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
        {
          active: !this.state.active,
        },
        // after state has been updated,
        () => {
          // set the class in state for the navbar accordingly
          this.state.active
              ? this.setState({
                navBarActiveClass: 'is-active',
              })
              : this.setState({
                navBarActiveClass: '',
              })
        }
    )
  }

  render() {
    const {langKey} = this.props.langs.find(({selected}) => selected)
    const en = langKey === 'en'
    return (
        <nav
            className="navbar is-transparent is-vcentered"
            role="navigation"
            aria-label="main-navigation"
            style={{height: 70}}
        >
          <div className="column is-10 is-offset-1 is-paddingless is-vcentered is-flex">
            <div className="container columns is-marginless-bottom is-paddingless is-vcentered"
                 style={{marginTop: 0, height: 70}}>
              <div className="navbar-brand" style={{height: 70, flex: 1}}>
                <Link to="/" className="navbar-item" title="Logo" style={{paddingLeft: 22}}>
                  <img src={logo} alt="Wohnmobile Kraus Logo" style={{maxHeight: '1.9rem'}}/>
                </Link>
                {/* Hamburger menu */}
                <div
                    className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                    data-target="navMenu"
                    style={{height: 70}}
                    onClick={() => this.toggleHamburger()}>
                  <span/>
                  <span/>
                  <span/>
                </div>
              </div>
              <div
                  id="navMenu"
                  className={`navbar-menu ${this.state.navBarActiveClass}`}
              >
                <div className="navbar-start has-text-centered">
                  <Link className="navbar-item"
                        to={en ? "/en/vw-busse-und-wohnmobile-mieten" : "/vw-busse-und-wohnmobile-mieten"}>
                    {en ? 'VW Buses & Motorhomes' : 'VW Busse & Wohnmobile'}
                  </Link>
                  <Link className="navbar-item" to={en ? "/en/preise" : "/preise"}>
                    {en ? 'rates' : 'Preise'}
                  </Link>
                  <Link className="navbar-item" to={en ? "en/contact" : "/contact"}>
                    {en ? 'Contact & booking' : 'Buchungsanfrage'}
                  </Link>
                  <Link className="navbar-item" to={en ? "en/faq" : "/faq"}>
                    {'FAQ'}
                  </Link>
                  {/*<div data-netlify-identity-menu></div>*/}
                </div>
                <SelectLanguage langs={this.props.langs} className="select-languages"/>
              </div>
            </div>
          </div>
        </nav>
    )
  }
}

export default Navbar
