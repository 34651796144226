import React from 'react'
import { Link } from 'gatsby'

import facebook from '../img/social/facebook.svg'
import logo from '../../static/img/logo-w.png'


const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Kaldi"
            style={{ width: '14em', height: 'auto' }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/contact" className="navbar-item">
                        Kontakt
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        Mietbedingungen
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/impressum">
                        Impressum
                      </Link>
                    </li>
                    <li>
                      <Link to={'/'} className="navbar-item" onClick={e => {e.preventDefault(); this.props.toggleData()}} >
                        Datenschutz
                      </Link>
                    </li>
                  </ul>

                </section>
              </div>
              <div className="column is-4 social is-offset-4">
                <a title="facebook"  href="https://www.facebook.com/WohnmobileKraus/">
                  <img
                      src={facebook}
                      alt="Facebook"
                      style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
