import React from "react";
import {IntlProvider} from "react-intl";

export default () => {

  return (
      <div
          className="image margin-top-0 is-flex"
          style={{
            backgroundImage: `url('/img/palmen-bg-dark.jpg')`,
            marginTop:0,
            marginBottom:0
          }}
      >
        <section className="hero is-flex container" style={{flex:1}}>
          <div className="hero-body">
            <div className="container">
              <div className="column is-10 is-offset-1">
                <h1 className="title is-centered" style={{color:'white'}}>
                  {"Newsletter abonnieren und für die Urlaubskasse sparen!"}
                </h1>
              </div>
              <div className="column is-10 is-offset-1">
                <p className="subtitle is-centere" style={{color:'#00afdc'}}>
                  {"Rabattaktionen - Last-Minute Angebote - Buchungslücken.\n" +
                  "Lassen Sie sich unseren E-Mail Service nicht entgehen.\n" +
                  "\n"}
                </p>
              </div>
              <div className={'is-centered is-flex'}>
                <iframe width="540" height="350"
                        src="https://42825ade.sibforms.com/serve/MUIEALnSfZDvyvpVtkbWbkaScBhxHrVQNYjYM62_k97G8ISMuNXAC-nu9Rl20ID6prY6BFrDvj0mdYijhuiB7n5Q1Yx-Idxz_epHeo6z9OmHWssSyKqZ73JtMT2BpU-zDyhhd9F9ylGv6OFJLcSHvkgyese8wLD7Rfw_fHp_m-jHj7NXhfONAIbD9aNP1aG2aHBE7oZCT5hQlBAB"
                        frameBorder="0" scrolling="auto" allowFullScreen
                        style={{display:'block', marginLeft:'auto', marginRight:'auto',maxWidth:'100%'}} />
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}
