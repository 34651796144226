import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { v4 } from 'uuid'
const SelectLanguage = (props) => {
  const isActive = ({ isCurrent }) => {
    return isCurrent ? { className: "active" } : null
  }

  const links = props.langs.filter(x => !x.selected).map(({langKey, link, selected, key}, i) =>
        <Link getProps={isActive} className="navbar-item" to={link} selected={selected} key={key} replace>
          {langKey}
        </Link>
  );

  return (
      <nav key={v4()} className="navbar">
        <div className="navbar-start has-text-centered">
          {links}
        </div>
      </nav>
  );
};

SelectLanguage.propTypes = {
  langs: PropTypes.array
};

export default SelectLanguage;
