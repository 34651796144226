import React, { useState } from "react";

export default function Claimer(props) {
  const isBrowser = typeof window !== "undefined";

  const [read, setRead] = useState(() => {
    if (isBrowser) {
      const store = sessionStorage.getItem("confirmation");
      return !!store;
    } else return false;
  });

  function setOk(value) {
    setRead(value);
    if (isBrowser) {
      sessionStorage.setItem("confirmation", "true");
    }
  }

  const offset = isBrowser ? window.innerHeight - 190 : 0;

  return (
    !read && (
      <div
        className={`${read ? "is-hidden" : "is-overlay"}`}
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: 99,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div
          style={{
            padding: "2% 10%",
            backgroundColor: "white",
            height: 400,
            width: 900,
          }}
        >
          <h4>Benutzererlebnis mit Cookies anpassen</h4>
          <p>
            Mit „Alle akzeptieren“ erklärst du dich damit einverstanden, dass
            wir Cookies auf deinem Gerät speichern, um dir die Nutzung unserer
            Website zu erleichtern und die Websitenutzung für unser Marketing zu
            analysieren. Weitere Informationen zu Cookies findest du in
            unserer&nbsp;
            <b
              style={{ color: "#00afdc", cursor: "pointer" }}
              onClick={() => props.open(true)}
            >
              Datenschutzerklärung
            </b>
            .
          </p>
          <br />
          <label className="checkbox">
            <input type="checkbox" disabled checked />
            &nbsp; Unbedingt erforderliche Cookies
          </label>
          <br />
          <label className="checkbox">
            <input type="checkbox" />
            &nbsp; Funktionelle Cookoes
          </label>
          <br />
          <label className="checkbox">
            <input type="checkbox" />
            &nbsp; Leistungs cookies
          </label>
          <br />
          <br />
          <div
            className="button is-rounded"
            style={{ borderColor: "#00afdc", marginRight: 16, marginTop: 8 }}
            onClick={() => setOk(true)}
          >
            <a
              style={{
                color: "#00afdc",
                fontWeight: 600,
                letterSpacing: ".1rem",
                fontSize: 11,
              }}
              className="is-uppercase is-raleway"
            >
              Auswahl speichern
            </a>
          </div>
          <div
            className="button is-rounded"
            style={{ borderColor: "#00afdc", marginRight: 16, marginTop: 8 }}
            onClick={() => setOk(true)}
          >
            <a
              style={{
                color: "#00afdc",
                fontWeight: 600,
                letterSpacing: ".1rem",
                fontSize: 11,
              }}
              className="is-uppercase is-raleway"
            >
              Nur notwendige
            </a>
          </div>
          <div
            className="button is-rounded bluebg"
            style={{ borderColor: "transparent", marginTop: 8 }}
            onClick={() => setOk(true)}
          >
            <a
              style={{
                color: "white",
                fontWeight: 600,
                letterSpacing: ".1rem",
                fontSize: 11,
              }}
              className="is-uppercase is-raleway"
            >
              Alle aktzeptieren
            </a>
          </div>
        </div>
      </div>
    )
  );
}
