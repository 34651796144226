import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import { IntlProvider } from "react-intl";
import en from "../data/messages/en-US";
import {
  getCurrentLangKey,
  getLangs,
  getUrlForLang,
  isHomePage,
} from "ptz-i18n";
import "intl";
import useSiteMetadata from "./SiteMetadata";
import { v4 } from "uuid";
import dsgvo from "../templates/dsgvo";
import Newsletter from "./Newsletter";
import Claimer from "./Claimer";
import favicon from "../../static/img/favicon.ico";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const messages = { en };
import preview from "../../static/img/preview.png";
const TRACKING_ID = "UA-120846457-1";
ReactGA.initialize(TRACKING_ID);

const TemplateWrapper = (props) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-5PTZVTC",
    };
    TagManager.initialize(tagManagerArgs);
    // console.log(TagManager);
    if (window) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);
  const hasWindow = Boolean(typeof window !== `undefined` && window.document);
  const [open, openDsgvo] = useState(false);

  const { children, location } = props;
  const { title, description, ...other } = useSiteMetadata();
  const langs = JSON.parse(other.languages);
  const { languages, defaultLangKey } = langs;
  const url = location.pathname;
  const isDE = !location.pathname.includes("/en/");
  const isHome = isHomePage(url, false, languages);
  const langKey = getCurrentLangKey(languages, defaultLangKey, url);
  const homeLink = `${langKey !== "en" ? "/" : langKey}`;
  const urlForlang = getUrlForLang(homeLink, url);
  const langsData = getLangs(languages, langKey, urlForlang);
  const canon =
    isDE && hasWindow
      ? `${window?.location?.host}/en${window?.location?.pathname}`
      : "";
  const langsMenu = langsData.map((item, i) => ({
    ...item,
    key: v4(),
    link: item.link.replace(`/de/`, "/"),
  }));
  const en = langKey === "en";
  return (
    <IntlProvider locale={langKey} messages={messages[langKey]}>
      <div className="is-relative">
        <Helmet>
          <html lang={langKey} />
          <title>{title}</title>
          <meta name="description" content={description} />
          <script
            src="https://kit.fontawesome.com/d242ffdc84.js"
            crossOrigin="anonymous"
            SameSite="None"
          />
          <link rel="icon" type="image/png" href={`${favicon}`} sizes="16x16" />
          <meta name="theme-color" content="#fff" />
          <meta
            name="google-site-verification"
            content="J9kHTok1-15vMvLSoSBihZ3RaYCEPfFFv3DZlt0kE08"
          />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-120846457-1"
          />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta
            property="og:description"
            content={
              "Mieten Sie bei uns einen VW Bus California oder ein anderes Wohnmobil für Ihren nächsten Campingurlaub. Günstig einen VW Bus mieten, ohne Kilometerbeschränkung!"
            }
          />
          <meta property="og:url" content="https://wohnmobilekraus.de" />
          <meta property="og:image" content={`${preview}`} />
          {isDE && <link rel="canonical" href={`${canon}`} />}
        </Helmet>
        <Navbar langs={langsMenu} />
        <div>{children}</div>
        <Newsletter />
        <Footer toggleData={() => openDsgvo(true)} />
      </div>
      <Claimer open={openDsgvo} />
      {open && (
        <div className={`modal is-active is-overlay`} style={{ zIndex: 100 }}>
          <div className="modal-background" />
          <div className="modal-content" style={{ backgroundColor: "white" }}>
            {dsgvo()}
          </div>
          <button
            className="modal-close is-large"
            onClick={() => openDsgvo(false)}
            aria-label="close"
          />
        </div>
      )}
    </IntlProvider>
  );
};

export default React.memo(TemplateWrapper);
